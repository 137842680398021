import { BasePropsBuilder } from '@/components/layouts/header/mobile/builder/BasePropsBuilder'
import * as Icons from '@kakaomobility/tui-icons-react'
import {
  AppWebviewSchemeType,
  createWebViewSchemeHandler,
} from '@/helpers/appSchemeHelper'

export class ClosePropsBuilder extends BasePropsBuilder {
  /* icon definitions */
  private readonly iconDefs = {
    close: {
      Icon: Icons.I24CloseBlack,
      label: '닫기',
    },
    cancel: {
      label: '취소',
    },
    debug: {
      label: 'test',
    },
    userManual: {
      label: '버스대절소개',
    },
  }

  get icon(): ClosePropsBuilderInstance['iconDefs'] {
    return this.iconDefs
  }

  /* handler definitions */
  private readonly handlerDefs = {
    back: () => {
      this.router.back()
    },
    close: createWebViewSchemeHandler(AppWebviewSchemeType.CLOSE),
  }

  get handler(): ClosePropsBuilderInstance['handlerDefs'] {
    return this.handlerDefs
  }
}

export type ClosePropsBuilderInstance = InstanceType<typeof ClosePropsBuilder>
