import React, { ReactElement } from 'react'
import styled from '@emotion/styled'

const PREFIX = `${process.env.NEXT_PUBLIC_CDN_URI}/icons/h24/`

export enum IconH24TypeFile {
  MINUS_BLUE = 'minus_on',
  MINUS_GRAY = 'minus_off',
  PLUS_BLUE = 'plus_on',
  PLUS_GRAY = 'plus_off',
  CLOSE_WHITE = 'close_white',
  CLOSE_BLACK = 'close_black',
  ARROW_UP_GRAY = 'arrow_up_gray',
  ARROW_DOWN_GRAY = 'arrow_down_gray',
  RADIO_OFF = 'radio_off',
  RADIO_ON = 'radio_on',
  DELETE_CIRCLE = 'delete_circle',
  BUS = 'bus',
  GATHERING = 'gathering',
  CHECK_BIG_BLUE = 'check_big_blue',
  CHECK_BIG_WHITE = 'check_big_white',
  CHECK_RECTANGLE_OFF = 'check_rectangle_off',
  CHECK_RECTANGLE_ON = 'check_rectangle_on',
  CHECK_SMALL_OFF = 'check_small_off',
  CHECK_SMALL_ON = 'check_small_on',
  ARROW_RIGHT = 'arrow_right',
  ARROW_RIGHT_DISABLE = 'arrow_right_disable',
  ARROW_RIGHT_BIG = 'arrow_right_big',
  ARROW_CIRCLE_LEFT = 'arrow_circle_left',
  ARROW_CIRCLE_RIGHT = 'arrow_circle_right',
  INFO_RED = 'info_red',
  INFO_GRAY = 'info_gray',
  BOOKING_TIME = 'booking_time',
  BOOKING_DATE = 'booking_date',
  BOOKING_MAP = 'booking_map',
  SCHEDULE_MAP_LINK = 'schedule_map_link',
  SCHEDULE_NONLINK = 'schedule_nonlink',
  T = 't',
  AUTONOMOUS = 'autonomous',
  CIRCLE_QUESTION = 'circle_question',
  BACK_BLACK = 'back_black',
  // Figma 이미지 파일 이름에 갑자기 PascalCase 출현
  ADD = 'Add',
  ADD_BLUE = 'add_blue',
  SHARE_TALK_YELLOW = 'share_talk_yellow',
}

const SIZE = '24px'
const StyledIcon = styled.i`
  display: inline-block;
  width: ${SIZE};
  height: ${SIZE};
  background-image: url(${(props: IconH24Props) =>
    `${PREFIX}${props.type}.png`}); // Compatibility
  background-image: url(${(props: IconH24Props) =>
      `${PREFIX}${props.type}.svg`}),
    none; // SVG
  background-size: ${SIZE} ${SIZE};
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
`

export interface IconH24Props {
  type: IconH24TypeFile
  show?: boolean | any
  className?: string
}

function IconH24({
  type,
  className,
  show = true,
}: IconH24Props): ReactElement | null {
  if (!show) return null
  return <StyledIcon type={type} className={className} />
}

export default IconH24
