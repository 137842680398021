/* dependencies */
import React, { ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
/* hooks */
import useScroll from '@/hooks/utils/useScroll'
import {
  useBuilder,
  PropsBuilder,
} from '@/components/layouts/header/mobile/builder'
/* components */
import LayoutMobileHeaderBack from '@/components/layouts/header/mobile/LayoutMobileHeaderBack'
import LayoutMobileHeaderTitle from '@/components/layouts/header/mobile/LayoutMobileHeaderTitle'
import LayoutMobileHeaderClose from '@/components/layouts/header/mobile/LayoutMobileHeaderClose'
/* tui-react-maas */
import {
  STYLE_CLEAR_FIX,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import IOSNotchFixer, {
  DirectionType,
} from '@/components/layouts/iOSNotchFixer'

const Styled = {
  header: styled.header`
    height: 56px;
  `,
  wrap: styled.div<{ isShowBorder: boolean; isModal?: boolean }>`
    ${STYLE_CLEAR_FIX};
    box-sizing: border-box;
    background-color: ${TColors.UC_WHITE};
    border-bottom: ${({ isShowBorder }) =>
      isShowBorder && `1px solid ${TColors.NEUTRAL5}`};
    ${({ isModal }) =>
      !isModal &&
      css`
        position: fixed;
        width: 100%;
        z-index: 2;
      `}
  `,
  container: styled.div`
    display: flex;
    align-items: center;
  `,
}

export interface LayoutMobileHeaderProps {
  propsBuilder?: PropsBuilder
}

export default function LayoutMobileHeader({
  propsBuilder,
}: LayoutMobileHeaderProps): ReactElement {
  const { scrollTop } = useScroll()
  const isShowBorder = useMemo(() => scrollTop >= 56, [scrollTop])
  const { backIconType, onBack, title, titleType, closeIconType, onClose } =
    useBuilder(propsBuilder)
  return (
    <Styled.header>
      <Styled.wrap isShowBorder={isShowBorder}>
        <IOSNotchFixer direction={DirectionType.TOP}>
          <Styled.container>
            <LayoutMobileHeaderBack
              onBack={onBack}
              backIconType={backIconType}
            />
            <LayoutMobileHeaderTitle title={title} titleType={titleType} />
            <LayoutMobileHeaderClose
              closeIconType={closeIconType}
              onClose={onClose}
            />
          </Styled.container>
        </IOSNotchFixer>
      </Styled.wrap>
    </Styled.header>
  )
}
