/* dependencies */
import React, { ReactElement } from 'react'
/* components */
import LayoutPCHeader from '@/components/layouts/header/desktop/LayoutPCHeader'
import LayoutMobileHeader, {
  LayoutMobileHeaderProps,
} from '@/components/layouts/header/mobile/LayoutMobileHeader'
import { useAppSelector } from '@/store'
import { isKakaoTSelector } from '@/store/selectors/device'

interface LayoutHeaderProps extends LayoutMobileHeaderProps {}

export default function LayoutHeader({
  propsBuilder,
}: LayoutHeaderProps): ReactElement | null {
  const isMobile = useAppSelector(isKakaoTSelector)
  if (!isMobile) return <LayoutPCHeader propsBuilder={propsBuilder} />
  else return <LayoutMobileHeader propsBuilder={propsBuilder} />
}
