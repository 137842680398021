import { useState } from 'react'
import {
  BackPropsBuilderInstance,
  TitlePropsBuilderInstance,
  ClosePropsBuilderInstance,
  BackPropsBuilder,
  TitlePropsBuilder,
  ClosePropsBuilder,
} from '@/components/layouts/header/mobile/builder'
import { LayoutMobileHeaderBackProps } from '@/components/layouts/header/mobile/LayoutMobileHeaderBack'
import { LayoutMobileHeaderTitleProps } from '@/components/layouts/header/mobile/LayoutMobileHeaderTitle'
import { LayoutMobileHeaderCloseProps } from '@/components/layouts/header/mobile/LayoutMobileHeaderClose'
import { useAppDispatch } from '@/store'
import { useRouter } from '@/router'

export interface BuiltHeaderProps
  extends LayoutMobileHeaderBackProps,
    LayoutMobileHeaderTitleProps,
    LayoutMobileHeaderCloseProps {}
export type PropsBuilder = ({
  backPropsBuilder,
  titlePropsBuilder,
  closePropsBuilder,
}: {
  backPropsBuilder: BackPropsBuilderInstance
  titlePropsBuilder: TitlePropsBuilderInstance
  closePropsBuilder: ClosePropsBuilderInstance
}) => BuiltHeaderProps

export const useBuilder = (propsBuilder?: PropsBuilder): BuiltHeaderProps => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const [backPropsBuilder] = useState(
    () => new BackPropsBuilder({ dispatch, router })
  )
  const [titlePropsBuilder] = useState(
    () => new TitlePropsBuilder({ dispatch, router })
  )
  const [closePropsBuilder] = useState(
    () => new ClosePropsBuilder({ dispatch, router })
  )
  if (propsBuilder) {
    return propsBuilder({
      backPropsBuilder,
      titlePropsBuilder,
      closePropsBuilder,
    })
  } else {
    return {}
  }
}
