import { useCallback, useEffect, useState } from 'react'

interface ScrollPosition {
  scrollHeight: number
  scrollTop: number
  scrollInnerHeight: number
  scrollBottom: number
}

function useScroll(): ScrollPosition {
  const [scroll, setScroll] = useState({
    scrollHeight: 0,
    scrollTop: 0,
    scrollInnerHeight: 0,
    scrollBottom: 0,
  })

  const onScroll = useCallback((): void => {
    const scrollHeight = window.document.body.scrollHeight
    const scrollTop = window.scrollY
    const scrollInnerHeight = window.document.body.offsetHeight
    const scrollBottom = scrollTop + scrollInnerHeight

    setScroll({
      scrollHeight,
      scrollTop,
      scrollInnerHeight,
      scrollBottom,
    })
  }, [])

  useEffect(() => {
    window.document.addEventListener('scroll', onScroll)
    return () => window.document.removeEventListener('scroll', onScroll)
  }, [])
  return scroll
}

export default useScroll
