import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import {
  STYLE_CLEAR_FIX,
  FONT_12,
  FONT_14_BOLD,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'

const Styled = {
  wrap: styled.div`
    ${STYLE_CLEAR_FIX};
    height: 62px;
    padding: 20px 30px;
    color: ${TColors.NEUTRAL2};
    font-style: normal;
    text-align: center;
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      text-align: right;
    }
  `,
  corporation: styled.strong`
    display: block;
    ${FONT_14_BOLD};
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      margin-top: 10px;
      text-align: left;
    }
  `,
  address: styled.address`
    display: block;
    font-style: normal;
    ${FONT_12};
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      clear: left;
      float: left;
    }
  `,
  policy: styled.a`
    display: inline-block;
    font-style: normal;
    text-decoration: none;
    color: ${TColors.NEUTRAL2};
    ${FONT_12};
    vertical-align: middle;
    &:hover {
      color: ${TColors.NEUTRAL1};
    }
  `,
}

function LayoutFooter(): ReactElement {
  return (
    <Styled.wrap>
      <Styled.corporation>
        &copy; {process.env.NEXT_PUBLIC_COMPANY_NAME}.
      </Styled.corporation>
      <Styled.address>{process.env.NEXT_PUBLIC_COMPANY_ADDRESS}</Styled.address>
      <Styled.policy
        href={process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}
        target='_blank'
        rel='noopener'
      >
        개인정보처리방침
      </Styled.policy>
    </Styled.wrap>
  )
}

export default LayoutFooter
