import { BasePropsBuilder } from '@/components/layouts/header/mobile/builder/BasePropsBuilder'
import * as Icons from '@kakaomobility/tui-icons-react'
import {
  AppWebviewSchemeType,
  createWebViewSchemeHandler,
} from '@/helpers/appSchemeHelper'
import { isServerSide } from '@kakaomobility/tui-react-maas'

export class BackPropsBuilder extends BasePropsBuilder {
  /* icon definitions */
  private readonly iconDefs = {
    back: {
      Icon: Icons.I24BackBlack,
      label: '뒤로 가기',
    },
    home: {
      Icon: Icons.I24Home,
      label: '홈',
    },
    close: {
      Icon: Icons.I24CloseBlack,
      label: '닫기',
    },
  }

  get icon(): BackPropsBuilderInstance['iconDefs'] {
    return this.iconDefs
  }

  /* handler definitions */
  private readonly handlerDefs = {
    back: () => {
      if (isServerSide()) return /* guard */
      if (window.history.length < 2)
        createWebViewSchemeHandler(AppWebviewSchemeType.CLOSE)()
      else this.router.back()
    },
    close: createWebViewSchemeHandler(AppWebviewSchemeType.CLOSE),
    backHome: createWebViewSchemeHandler(AppWebviewSchemeType.HOME),
    backTravel: createWebViewSchemeHandler(AppWebviewSchemeType.TRAVEL),
  }

  get handler(): BackPropsBuilderInstance['handlerDefs'] {
    return this.handlerDefs
  }
}

export type BackPropsBuilderInstance = InstanceType<typeof BackPropsBuilder>
