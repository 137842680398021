/* dependencies */
import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
/* hooks */
/* tui-react-maas */
import { FONT_18, TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'

const Styled = {
  backButton: styled.button`
    display: block;
    margin: 0;
    padding: 0;
    width: 56px;
    height: 56px;
  `,
  backText: styled.button`
    margin-left: 16px;
    display: block;
    width: fit-content;
    height: 56px;
    ${FONT_18};
    color: ${TColors.NEUTRAL1};
  `,
}

export interface LayoutMobileHeaderBackProps {
  backIconType?: { label?: string; Icon?: React.FC<any> }
  onBack?: (() => Promise<void>) | (() => void)
}

export default function LayoutMobileHeaderBack({
  onBack,
  backIconType,
}: LayoutMobileHeaderBackProps): ReactElement | null {
  if (!backIconType || !onBack) return null /* guard */
  if (backIconType.Icon) {
    return (
      <Styled.backButton
        type={'button'}
        onClick={onBack}
        className={'layout_header_back_button'}
      >
        <backIconType.Icon label={backIconType?.label} />
      </Styled.backButton>
    )
  } else {
    return (
      <Styled.backText
        type={'button'}
        onClick={onBack}
        className={'layout_header_back_button'}
      >
        {backIconType.label}
      </Styled.backText>
    )
  }
}
