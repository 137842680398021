import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'

const Styled = {
  hamburger: styled.i<IconHamburgerProps>`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    margin: -1px 0 0 -10px;
    &,
    &::before,
    &::after {
      display: block;
      height: 2px;
      border: 0;
      border-radius: 1px;
      transition: transform 0.1s ease, background-color 0.1s ease,
        opacity 0.1s ease;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
    }
    ${({ cross }) =>
      cross
        ? css`
            & {
              background-color: transparent;
            }
            &::before {
              top: 0;
              bottom: auto;
              transform: rotate(45deg);
            }
            &::after {
              top: 0;
              transform: rotate(-45deg);
            }
            &::before,
            &::after {
              background-color: ${TColors.PRIMARY2};
            }
          `
        : css`
            &,
            &::before,
            &::after {
              background-color: ${TColors.NEUTRAL1};
            }
            &::before {
              bottom: 6px;
            }
            &::after {
              top: 6px;
            }
          `}
  `,
}

export interface IconHamburgerProps {
  cross: boolean
}

function IconHamburger({ cross }: IconHamburgerProps): ReactElement {
  return <Styled.hamburger cross={cross} />
}

export default IconHamburger
