import { isKakaoT } from '@/helpers/deviceInfo'

/**
 * @see https://wiki.daumkakao.com/pages/viewpage.action?pageId=645822496
 */

const T_DEEP_LINK_URI = isKakaoT
  ? process.env.NEXT_PUBLIC_T_APP_SCHEME_KAKAOT
  : process.env.NEXT_PUBLIC_T_WEB_DEEP_LINK_URL

export enum AppSchemeHostType {
  LAUNCH = 'launch',
}

export enum AppSchemePageType {
  NONE = '',
  PAYMENT = 'payment',
}

export enum AppWebviewSchemeType {
  CLOSE = 'webview://close',
  HOME = 'kakaot://launch?page=home&tab=HOME',
  TRAVEL = 'kakaot://launch?page=home&tab=TRAVEL',
}

export enum KakaoDeepLink {
  KAKAOPAY_UNLOCK = 'kakaotalk://kakaopay/unlock',
}

export function getLaunchAppURI(
  pageType: AppSchemePageType = AppSchemePageType.NONE,
  params: string = ''
): string {
  return `${T_DEEP_LINK_URI}${AppSchemeHostType.LAUNCH}?page=${pageType}${params}`
}

export function goLaunchApp(
  pageType: AppSchemePageType = AppSchemePageType.NONE,
  params: string = ''
): string {
  return (window.location.href = getLaunchAppURI(pageType, params))
}

export function createWebViewSchemeHandler(scheme: AppWebviewSchemeType) {
  return function handleWebViewScheme() {
    return (window.location.href = scheme)
  }
}

export function goWebViewScheme(scheme: AppWebviewSchemeType): void | string {
  return createWebViewSchemeHandler(scheme)()
}
