import React, { PropsWithChildren, ReactElement } from 'react'
import styled from '@emotion/styled'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import LayoutFooter from '@/components/layouts/LayoutFooter'
import IOSNotchFixer, {
  DirectionType,
} from '@/components/layouts/iOSNotchFixer'
import {
  STYLE_MOMENTUM_SCROLL,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import { useAppSelector } from '@/store'
import { isKakaoTSelector } from '@/store/selectors/device'

interface LayoutFrameOptionalProps {
  fluid: boolean // width: 100%
  fixedOnMobile: boolean // height: 100% 고정, body 내부 스크롤
  className: string
  showFooter?: boolean
  header: ReactElement
  /** 헤더를 주입할 수 있게 함 */
  customHeader?: ReactElement | null
}

type LayoutFrameProps = Partial<LayoutFrameOptionalProps>

interface LayoutFrameMainProps {
  fixedOnMobile: boolean
  fluid: boolean
}

interface LayoutFrameFooterProps {
  fixedOnMobile: boolean
}

const Styled = {
  body: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: calc(100vh - 60px);
    margin: 0 auto;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
  `,
  main: styled.div<LayoutFrameMainProps>`
    flex: 1 1 auto;
    ${({ fixedOnMobile }) => fixedOnMobile && STYLE_MOMENTUM_SCROLL};
    min-width: ${STYLE_BREAKPOINT.M_WIDTH};
    max-width: ${({ fluid }) => !fluid && STYLE_BREAKPOINT.T_WIDTH};
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
  `,
  footer: styled.footer<LayoutFrameFooterProps>`
    flex-shrink: 0;
    flex: ${({ fixedOnMobile }) => fixedOnMobile && '0 0 auto'};
    display: block;
    border-top: 1px solid ${TColors.NEUTRAL6};
  `,
}

export default function LayoutFrame({
  fluid = false,
  fixedOnMobile = false,
  className,
  children,
  header,
  showFooter = true,
}: PropsWithChildren<LayoutFrameProps>): ReactElement {
  const isMobileApp = useAppSelector(isKakaoTSelector)
  return (
    <>
      <>{header}</>
      <IOSNotchFixer direction={DirectionType.VERTICAL}>
        <Styled.body id='main-content'>
          <Styled.main
            className={`${className} main-layout`}
            fixedOnMobile={fixedOnMobile}
            fluid={fluid}
          >
            <>{children}</>
          </Styled.main>
          <>
            {!isMobileApp && showFooter && (
              <Styled.footer fixedOnMobile={fixedOnMobile}>
                <LayoutFooter />
              </Styled.footer>
            )}
          </>
        </Styled.body>
      </IOSNotchFixer>
    </>
  )
}
