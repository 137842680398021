import React, {
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react'
import useAuthentication from '@/services/hooks/queries/accounts/useAuthentication'
import IconHamburger from '@/components/IconHamburger'
import styled from '@emotion/styled'
// import { PageBookingList } from '@/routes/paths/bookings'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { PageShuttleMain, PageBookingList } from '@/router'
import {
  TButton,
  FONT_14,
  ButtonThemeType,
  ButtonSizeType,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import Link from 'next/link'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  mobileMenu: styled.button<LayoutHeaderMenuStyleProps>`
    z-index: 310;
    position: ${({ opened }) => (opened ? 'fixed' : 'absolute')};
    top: 16px;
    right: 20px;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    border: 0;
    background: none transparent;
    outline: none;
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      display: none;
    }
  `,
  menuWrap: styled.div<LayoutHeaderMenuStyleProps>`
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    min-width: 208px;
    padding: 44px 0 16px;
    background-color: ${TColors.PRIMARY3};
    transform: translateX(${({ opened }) => (opened ? '0' : '100%')});
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      position: relative;
      display: inline-block;
      min-width: 0;
      padding: 0;
      transform: none;
      background-color: transparent;
      text-align: right;
      font-size: 0;
      line-height: 0;
    }
  `,
  menuItemLink: styled.a`
    cursor: pointer;
    float: left;
    clear: both;
    padding: 12px 20px;
    color: ${TColors.PRIMARY2};
    ${FONT_14};
    &:active {
      background-color: ${TColors.PRIMARY3_PRESSED};
    }
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      float: none;
      display: inline-block;
      padding: 5px 12px;
      color: ${TColors.NEUTRAL1};
      vertical-align: middle;
      &:active {
        background-color: ${TColors.BG};
      }
    }
  `,
  menuItemButton: styled(TButton)`
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    @media (max-width: ${STYLE_BREAKPOINT.T_WIDTH_UNDER}) {
      float: left;
      clear: both;
      padding: 12px 20px;
      margin: 0;
      color: ${TColors.PRIMARY2};
      background-color: transparent;
      border: 0 none;
      ${FONT_14};
      &:active {
        background-color: ${TColors.PRIMARY3_PRESSED};
      }
    }
  `,
}

enum LayoutHeaderMenuType {
  HANDLER,
  LINK,
  OUT_LINK,
}

interface LayoutHeaderMenu {
  type: LayoutHeaderMenuType
  label: string
  handler?: (...args: any[]) => void
}

interface LayoutHeaderMenuLink extends LayoutHeaderMenu {
  type: LayoutHeaderMenuType.LINK
  href: string
}

interface LayoutHeaderMenuOutLink extends LayoutHeaderMenu {
  type: LayoutHeaderMenuType.OUT_LINK
  href: string
  handler?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

interface LayoutHeaderMenuHandler extends LayoutHeaderMenu {
  type: LayoutHeaderMenuType.HANDLER
  handler: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
}

type LayoutHeaderMenuItem =
  | LayoutHeaderMenuLink
  | LayoutHeaderMenuOutLink
  | LayoutHeaderMenuHandler

interface LayoutHeaderMenuStyleProps {
  opened: boolean
}

function LayoutHeaderMenu(): ReactElement {
  const { loggedIn, handleLogin, handleLogout } = useAuthentication()
  const [opened, setOpened] = useState(false)

  const mobileMenuHandler = (): void => setOpened(!opened)
  const menuItemList: LayoutHeaderMenuItem[] = [
    {
      type: LayoutHeaderMenuType.LINK,
      label: '셔틀 메인',
      href: PageShuttleMain.toString(),
    },
    {
      type: LayoutHeaderMenuType.LINK,
      label: '예약내역',
      href: PageBookingList.toString(),
    },
    {
      type: LayoutHeaderMenuType.OUT_LINK,
      label: '자주 묻는 질문',
      href: process.env.NEXT_PUBLIC_SHUTTLE_FAQ_URL as string,
      handler() {
        console.debug('자주 묻는 질문', process.env.NEXT_PUBLIC_SHUTTLE_FAQ_URL)
      },
    },
    {
      type: LayoutHeaderMenuType.HANDLER,
      label: loggedIn ? '로그아웃' : '로그인',
      handler: loggedIn ? handleLogout : handleLogin,
    },
  ]

  const menuElements = menuItemList.map((menuItem, index) => {
    let menu
    switch (menuItem.type) {
      case LayoutHeaderMenuType.LINK:
        menu = (
          <Link key={`LayoutHeaderMenuItem_${index}`} href={menuItem.href}>
            <Styled.menuItemLink href={menuItem.href} role='menuitem'>
              {menuItem.label}
            </Styled.menuItemLink>
          </Link>
        )
        break
      case LayoutHeaderMenuType.OUT_LINK:
        menu = (
          <Styled.menuItemLink
            key={`LayoutHeaderMenuItem_${index}`}
            href={menuItem.href}
            target='_blank'
            rel='noopener'
            role='menuitem'
          >
            {menuItem.label}
            <Hidden>새창열림</Hidden>
          </Styled.menuItemLink>
        )
        break
      case LayoutHeaderMenuType.HANDLER:
        menu = (
          <Styled.menuItemButton
            type='button'
            key={`LayoutHeaderMenuItem_${index}`}
            btnTheme={ButtonThemeType.OUTLINE}
            size={ButtonSizeType.SMALL}
            onClick={menuItem.handler as MouseEventHandler}
          >
            {menuItem.label}
          </Styled.menuItemButton>
        )
    }
    return menu
  })

  return (
    <>
      <Styled.mobileMenu
        type='button'
        opened={opened}
        onClick={mobileMenuHandler}
        aria-expanded={opened}
      >
        <IconHamburger cross={opened} />
        <Hidden>메뉴</Hidden>
      </Styled.mobileMenu>
      <Styled.menuWrap role='menu' opened={opened}>
        {menuElements}
      </Styled.menuWrap>
    </>
  )
}

export default LayoutHeaderMenu
