/* dependencies */
import React, { ReactElement, useMemo } from 'react'
import styled from '@emotion/styled'
import useScroll from '@/hooks/utils/useScroll'
import { TitlePropsBuilderInstance } from '@/components/layouts/header/mobile/builder'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  title: styled.h1`
    margin-left: 16px;
    overflow: hidden;
    display: inline-block;
    font-size: 18px;
    &:before {
      height: 18px;
      content: ' ';
    }
  `,
}

export interface LayoutMobileHeaderTitleProps {
  titleType?: keyof TitlePropsBuilderInstance['titleType']
  title?: string | ReactElement
}

export default function LayoutMobileHeaderTitle({
  title,
  titleType,
}: LayoutMobileHeaderTitleProps): ReactElement {
  const { scrollTop } = useScroll()
  const showTitle = useMemo(() => {
    return scrollTop >= 56 && titleType === 'scrolled'
  }, [scrollTop, titleType])

  const renderedTitle = useMemo(() => {
    if (showTitle)
      return (
        <Styled.title className={'layout_header_title'}>
          <>{title}</>
        </Styled.title>
      )
    else if (titleType !== 'scrolled')
      return (
        <Styled.title className={'layout_header_title'}>
          <>{title}</>
        </Styled.title>
      )
    else
      return (
        <Styled.title className={'layout_header_title'}>
          <Hidden>
            <>{title}</>
          </Hidden>
        </Styled.title>
      )
  }, [title, showTitle, titleType])
  return renderedTitle
}
