import { AppDispatch } from '@/store'
import { ExtendedNextRouter } from '@/router'

export class BasePropsBuilder {
  protected readonly dispatch: AppDispatch
  protected readonly router: ExtendedNextRouter

  constructor({
    dispatch,
    router,
  }: {
    dispatch: AppDispatch
    router: ExtendedNextRouter
  }) {
    this.dispatch = dispatch
    this.router = router
  }
}
