/* dependencies */
import { ReactElement } from 'react'
import styled from '@emotion/styled'
/* tui-react-maas */
import { FONT_16, TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'

const Styled = {
  closeButton: styled.button`
    margin-left: auto;
    text-align: right;
    margin-right: 20px;
    display: block;
    width: fit-content;
    height: 56px;
    ${FONT_16};
    color: ${TColors.NEUTRAL1};
  `,
}

export interface LayoutMobileHeaderCloseProps {
  closeIconType?: { label?: string; Icon?: React.FC<any> }
  onClose?: (() => Promise<void>) | (() => void)
  className?: string
}

function CloseButton({
  closeIconType,
  onClose,
  className,
}: Required<LayoutMobileHeaderCloseProps>): ReactElement {
  if (closeIconType.Icon) {
    return (
      <Styled.closeButton
        onClick={onClose}
        className={className}
        type={'button'}
      >
        <closeIconType.Icon label={closeIconType.label} />
      </Styled.closeButton>
    )
  } else {
    return (
      <Styled.closeButton
        onClick={onClose}
        className={className}
        type={'button'}
      >
        {closeIconType.label}
      </Styled.closeButton>
    )
  }
}

export default function LayoutMobileHeaderClose({
  closeIconType,
  onClose,
}: LayoutMobileHeaderCloseProps): ReactElement | null {
  if (!closeIconType || !onClose) return null /* guard */
  return (
    <CloseButton
      className={'layout_header_close_button'}
      closeIconType={closeIconType}
      onClose={onClose}
    />
  )
}
