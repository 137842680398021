import { BasePropsBuilder } from '@/components/layouts/header/mobile/builder/BasePropsBuilder'

export class TitlePropsBuilder extends BasePropsBuilder {
  private readonly titleTypeDefs = {
    scrolled: 'scrolled' as const,
  }

  get titleType(): TitlePropsBuilderInstance['titleTypeDefs'] {
    return this.titleTypeDefs
  }
}

export type TitlePropsBuilderInstance = InstanceType<typeof TitlePropsBuilder>
