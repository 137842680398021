export enum STYLE_BREAKPOINT {
  // Mobile
  // - width
  M_WIDTH = '360px',

  // - height
  M_HEIGHT = '480px',

  // Tablet
  // - width
  T_WIDTH_UNDER = '767px',
  T_WIDTH = '768px',
  T_WIDTH_UPPER = '769px',
}
