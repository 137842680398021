import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import LayoutHeaderMenu from '@/components/layouts/header/desktop/LayoutHeaderMenu'
import IconH24, { IconH24TypeFile } from '@/components/IconH24'
import { STYLE_POINTER } from '@/styles/presets'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'
import { PageShuttleMain, useRouter } from '@/router'
import {
  PropsBuilder,
  useBuilder,
} from '@/components/layouts/header/mobile/builder'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  header: styled.header`
    height: 56px;
    background-color: ${TColors.PRIMARY2};
    border-bottom: 1px solid ${TColors.NEUTRAL5};
  `,
  skipNav: styled.div`
    box-sizing: border-box;
    > a {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
      padding: 4px 8px;
      background-color: #262d39;
      color: #fff;
      font-size: 16px;
      text-decoration: none;
      transform: translateY(-100%);
      &:focus {
        transform: translateY(0);
      }
    }
  `,
  wrap: styled.div`
    position: relative;
    padding: 16px 20px;
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      padding: 12px 30px;
      text-align: right;
    }
  `,
  title: styled.button`
    text-align: center;
    ${STYLE_POINTER};
    @media all and (min-width: ${STYLE_BREAKPOINT.T_WIDTH}) {
      padding: 4px 0;
      float: left;
    }
  `,
  titleIcon: styled.span`
    display: block;
  `,
}

interface LayoutPCHeaderProps {
  propsBuilder?: PropsBuilder
}

function LayoutPCHeader({ propsBuilder }: LayoutPCHeaderProps): ReactElement {
  const { title } = useBuilder(propsBuilder)
  const router = useRouter()

  function handleClickTitle(): void {
    router.push(PageShuttleMain)
  }

  return (
    <Styled.header>
      <Styled.skipNav>
        <a href='#main-content'>본문 바로가기</a>
      </Styled.skipNav>
      <Styled.wrap>
        <Styled.title type='button' role='link' onClick={handleClickTitle}>
          <Styled.titleIcon>
            <IconH24 type={IconH24TypeFile.T} />
            <Hidden>KaKao T 셔틀</Hidden>
          </Styled.titleIcon>
        </Styled.title>
        <LayoutHeaderMenu />
      </Styled.wrap>
      <Hidden as='h1'>
        <>{title}</>
      </Hidden>
    </Styled.header>
  )
}

export default LayoutPCHeader
