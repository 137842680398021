import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface IOSNotchFixerProps {
  direction: DirectionType
}

export enum DirectionType {
  TOP = 'top',
  VERTICAL = 'vertical',
}

const IOSNotchFixer = styled.div<IOSNotchFixerProps>`
  ${({ direction }) =>
    direction === DirectionType.TOP &&
    css`
      padding-top: 0;
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      height: calc(100% - 60px);
    `};

  ${({ direction }) =>
    direction === DirectionType.VERTICAL &&
    css`
      padding: 0;
      padding: constant(safe-area-inset-top) 0 constant(safe-area-inset-bottom)
        0;
      padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
      height: calc(100% - 60px);
    `};
`

export default IOSNotchFixer
